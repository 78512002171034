import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const NotePage = () => {

    const blocks = [
        {
            content: 'Playdrop.gg is not a betting website. There is no money or skin deposits, it’s free to play. Anyone can participate and have a chance to win cool prizes. The “betting” terms used on Playdrop.gg don’t make it a betting website.',
            //title: 'NOT a betting website',
        },
    ];
    const title = "NOT a betting website";

    return (
        <Layout 
            title="NOT a betting website"
        >
            <SimplePage
                blocks={blocks}
                title={title}
            />
        </Layout>
    )
}

export default NotePage;